<template>
  <div class="wrapper">
    <div>
      <CToaster :autohide="3000">
        <template v-for="info in infoList">
          <CToast
            :key="info.message"
            :show="true"
            :header="info.header"
            :color="info.color"
          >
            {{ info.message }}.
          </CToast>
        </template>
      </CToaster>
      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardHeader> Disburstment Info </CCardHeader>
            <CCardBody>
              <CTabs add-tab-classes="mt-1">
                <CTab active>
                  <template slot="title">
                    <CIcon name="cil-basket" /> Document Submission
                  </template>
                  <WidgetsReportDocumentSubmission
                    :company="company"
                    :stock="stock"
                  >
                  </WidgetsReportDocumentSubmission>
                </CTab>
                <CTab>
                  <template slot="title">
                    <CIcon name="cil-basket" /> Account Notice
                  </template>
                  <WidgetsReportAccountNotice
                        :company="company"
                        :stock="stock"
                      />
                </CTab>
                <CTab>
                  <template slot="title">
                    <CIcon name="cil-calculator" /> Full Invoice
                  </template>
                  <WidgetsReportFullInvoice
                        :company="company"
                        :stock="stock"
                        
                      ></WidgetsReportFullInvoice>
                </CTab>
              <CTab>
                  <template slot="title">
                    <CIcon name="cil-basket" /> Invoice EOE
                  </template>
                  <WidgetsReportEoeInvoice
                        :company="company"
                        :stock="stock"
                     
                      ></WidgetsReportEoeInvoice>
                </CTab>    <!---->
              </CTabs>
            </CCardBody>
            <CCardFooter>
              <!-- <CButton type="submit" size="sm" color="primary" @click="submit"
                ><CIcon name="cil-check-circle" /> Submit</CButton
              >

              <CButton class="ml-1" color="success"> Validate </CButton>
              <CButton class="ml-1" color="danger"> Reset </CButton> -->
              <CButton class="ml-1" color="secondary" @click="cancel">
                Back
              </CButton>
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import CompanyApi from "../../lib/companyApi";
import StockApi from "../../lib/stockApi";
import WidgetsReportAccountNotice from "../widgets/WidgetsReportAccountNotice";
import WidgetsReportFullInvoice from "../widgets/WidgetsReportFullInvoice";
import WidgetsReportDocumentSubmission from "../widgets/WidgetsReportDocumentSubmission";
import WidgetsReportEoeInvoice from "../widgets/WidgetsReportEoeInvoice";

import moment from "moment";

export default {
  name: "Disburstment",
  components: {
    WidgetsReportAccountNotice,
    WidgetsReportFullInvoice,
    WidgetsReportDocumentSubmission,
    WidgetsReportEoeInvoice,
  },

  data: () => {
    return {
      stockApi: new StockApi(),
      stock: {},
      companyApi: new CompanyApi(),
      company: {
        name: "",
        regNo: "",
        tagLine: "",
        address: "",
        phone: "",
        logoUrl: "", // replace with your actual logo path
        bankAccountName: "",
        bankAccountNo: "",
        bankName: "",
        bankAddress: "",
      },

      customer: {
        name: "Dayana Alia Binti M Khalit",
        registrationNumber: "VHB112",
        icNumber: "790505-03-5735",
        address: "LOT 654 JALAN SENA, TAMAN WANGI, 18300 GUA MUSANG, KELANTAN",
      },
      invoice: {
        number: "SA 674",
        date: "21.05.2024",
        items: [
          { description: "Selling Price", amount: 134258.42 },
          { description: "Vehicle Regn. Fee", amount: 350.0 },
          { description: "Vehicle Transfer Fee", amount: 434.4 },
          { description: "Road Tax", amount: 1957.18 },
          { description: "Insurance", amount: 0.0 },
          { description: "Others", amount: 0.0 },
        ],
        totalAmount: 137000.0,
        tradeIn: 32000.0,
        deposit: 0.0,
        netAmountDue: 105000.0,
      },
      vehicle: {
        chassisNo: "ZRR80-0556920",
        engineNo: "3ZRCS578153",
        regnNo: "DCC262",
        model: "TOYOTA VOXY",
      },
      report: {
        date: "21.05.2024",
        documentNumber: "VHB112",
        bank: "MIBB-JLN KELANG LAMA",
        reportNumber: "SA 662",
        items: [
          { description: "INVOICE", remarks: "✔" },
          { description: "HANDLING FEE LETTER", remarks: "✔" },
          { description: "INDEMNITY LETTER", remarks: "✔" },
          { description: "ORIGINAL @ COPY REGN. CARD", remarks: "✔" },
          { description: "INSURANCE", remarks: "✔" },
          { description: "COPY ROAD TAX", remarks: "✔" },
          { description: "TRANSFER NAME ORIGINAL RECEIPT", remarks: "✔" },
          { description: "COPY AP", remarks: "✔" },
          { description: "COPY B2", remarks: "✔" },
          { description: "B7 (ORIGINAL)", remarks: "✔" },
          { description: "IC HIRER", remarks: "✔" },
          { description: "DELIVERY ORDER", remarks: "✔" },
        ],
        remarksFooter: "BAWA BALIK LOU ORIGINAL",
      },
      bank: {
        name: "MAYBANK ISLAMIC BERHAD (JLN KELANG LAMA)",
        address:
          "2nd & 3rd Floor, 18 & 3 Jalan Desa Taman Desa, 58100 Kuala Lumpur",
      },
      invoice2: {
        number: "SA 662",
        date: "21.05.2024",
        doNumber: "",
        poNumber: "",
        salesman: "",
        items: [
          {
            qty: "1 Case",
            details: [
              "HIRER NAME: DAYANA ALIA BINTI M KHALIT",
              "VEHICLE MODEL: HONDA NBPX CUSTOM GL TURBO",
              "REGN. NO: VHB112",
            ],
            unitPrice: 600,
            amount: 600,
          },
        ],
        totalAmount: 600,
        totalInWords: "Six Hundred Only",
      },
      infoList: [],
      submitted: false,
    };
  },
  mounted() {
    var self = this;
    self.resetCompanyObj();
    self.resetStockObj();
  },
  computed: {},
  methods: {
    getImageUrl(item) {
      var url = apiUrl + "Documents/File/" + item.documentId;
      console.log(url);
      return url;
    },
    getDisplayDateTime(dt) {
      return moment(dt).format("DD/MM/YYYY HH:mm:ss");
    },

    onSubmit() {
      // var self = this;
      // if (!self.obj.id) {
      //   this.api
      //     .create(self.obj)
      //     .then((response) => {
      //       console.log(response);
      //       self.obj = response.result;
      //       self.$router.push({ path: `/admins/Stock/${response.result.id}` });
      //       //self.$router.push({ path: "/admins/StockList" });
      //     })
      //     .catch(({ data }) => {
      //       self.toast("Error", helper.getErrorMessage(data), "danger");
      //     });
      // } else {
      //   this.api
      //     .update(self.obj)
      //     .then((response) => {
      //       self.$router.push({ path: `/admins/Stock/${self.obj.id}` });
      //     })
      //     .catch(({ data }) => {
      //       self.toast("Error", helper.getErrorMessage(data), "danger");
      //     });
      // }
    },
    onReset(evt) {
      this.company = {};
    },
    toast(header, message, color) {
      var self = this;
      self.infoList.push({
        header: header,
        message: message,
        color: color,
      });
    },
    resetStockObj() {
      var self = this;
      this.stockApi
        .get(self.$route.params.id)
        .then((response) => {
          self.stock = response.result;
          console.log(self.stock);
        })
        .catch((data) => {
          self.toast("Error", data, "danger");
        });
    },

    resetCompanyObj() {
      var self = this;
      this.companyApi
        .getList()
        .then((response) => {
          console.log(response);
          self.company = response.result[0];
          console.log(self.company);
        })
        .catch((data) => {
          self.toast("Error", data, "danger");
        });
    },
    submit() {
      this.onSubmit();
      this.submitted = true;
    },
    cancel() {
      this.$router.push({ path: "/admins/DisburstmentList" });
    },
  },
};
</script>
