<template>
  <div ref="pdfContent" class="pdf-content">
    <CCard>
      <CCardHeader>
        <div class="float-right no-print">
          <!-- <a href="#" class="btn btn-sm btn-info" @click="saveAsPDF">
            <CIcon name="cil-save" /> Save as PDF
          </a> -->
          <a href="#" class="btn btn-sm btn-info ml-1" @click="printContent">
            <CIcon name="cil-print" class="mr-1" /> Print
          </a>
        </div>
      </CCardHeader>
      <CCardBody>
        <CRow class="justify-content-between align-items-center">
          <CCol xs="4" sm="3" md="2">
            <img
              :src="getImageUrl()"
              alt="Logo Syarikat"
              class="img-fluid logo-image"
            />
          </CCol>
          <CCol>
            <h1>{{ company.name }}</h1>
            <p>{{ company.tagLine }}</p>
            <p>{{ "(" + company.regNo + ")" }}</p>
            <p v-html="formatAddress(company.address)"></p>
            <p>Tel: {{ company.phone }}</p>
          </CCol>
        </CRow>
        <hr class="thick-hr" />
        <div class="invoice-header">
          <h3 class="text-center">INVOICE</h3>
          <CRow>
            <CCol md="6">
              <p><strong>To Customer:</strong></p>
              <p>{{ getLoanBankName() }}</p>
              <p v-html="formatAddress(getLoanBankAddress())"></p>
            </CCol>
            <CCol md="6" class="text-right">
              <p><strong>Invoice No:</strong> {{ stock.stockNo }}</p>
              <p><strong>Date:</strong> {{ getCurrentDate() }}</p>
              <p><strong>D.O No:</strong> <label>___________</label></p>
              <p><strong>P.O No:</strong> <label>___________</label></p>
              <p><strong>Salesman:</strong> <label>___________</label></p>
            </CCol>
          </CRow>
        </div>
        <div class="invoice-details">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Qty</th>
                <th>Particulars</th>
                <th>Unit Price</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1 Case</td>
                <td>
                  <p>HIRER NAME: {{ getCustomerName() }}</p>
                  <p>
                    VEHICLE MODEL: {{ stock.vehicle.brand.name }} -
                    {{ stock.vehicle.model.name }}
                  </p>
                  <p>
                    REGN. NO: {{ stock.registration.vehicleRegistrationNumber }}
                  </p>
                </td>
                <td>{{ stock.sale.eoeAmount.toFixed(2) }}</td>
                <td>{{ stock.sale.eoeAmount.toFixed(2) }}</td>
              </tr>
              <tr>
                <td>Ringgit</td>
                <td colspan="3">
                  {{ getEoeAmountInWords() }}
                </td>
              </tr>

              <tr>
                <td colspan="2"><strong>E. & O. E.</strong></td>
                <td><strong>Total</strong></td>
                <td>
                  <strong>{{ stock.sale.eoeAmount.toFixed(2) }}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="invoice-footer">
          <br />
          <br />
          <br />
          <br />
          <div class="signature text-right">
            <p>For {{ company.name }}</p>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";

export default {
  name: "WidgetsReportInvoice2",
  props: {
    company: {
      type: Object,
      required: true,
      validator: (value) => {
        return ["name", "tagLine", "address", "phone", "logoUrl"].every(
          (prop) => prop in value
        );
      },
    },
    stock: {
      type: Object,
      required: true,
    },
    // bank: {
    //   type: Object,
    //   required: true,
    //   validator: (value) => {
    //     return [
    //       "name",
    //       "address",
    //     ].every((prop) => prop in value);
    //   },
    // },
    // invoice2: {
    //   type: Object,
    //   required: true,
    //   validator: (value) => {
    //     return [
    //       "number",
    //       "date",
    //       "doNumber",
    //       "poNumber",
    //       "salesman",
    //       "items",
    //       "totalAmount",
    //       "totalInWords",
    //     ].every((prop) => prop in value);
    //   },
    // },
  },
  methods: {
    getCustomerName() {
      try {
        return this.stock.sale.customer.name;
      } catch (error) {
        return "N/A";
      }
    },
    getEoeAmountInWords() {
      var num = this.stock.sale.eoeAmount;
      const belowTwenty = [
        "zero",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "ten",
        "eleven",
        "twelve",
        "thirteen",
        "fourteen",
        "fifteen",
        "sixteen",
        "seventeen",
        "eighteen",
        "nineteen",
      ];

      const tens = [
        "",
        "",
        "twenty",
        "thirty",
        "forty",
        "fifty",
        "sixty",
        "seventy",
        "eighty",
        "ninety",
      ];

      const aboveHundred = ["", "thousand", "million", "billion", "trillion"];

      function helper(n) {
        if (n < 20) return belowTwenty[n];
        else if (n < 100)
          return (
            tens[Math.floor(n / 10)] +
            (n % 10 === 0 ? "" : " " + belowTwenty[n % 10])
          );
        else
          return (
            belowTwenty[Math.floor(n / 100)] +
            " hundred" +
            (n % 100 === 0 ? "" : " " + helper(n % 100))
          );
      }

      if (num === 0) return "zero";

      let word = "";
      let i = 0;

      while (num > 0) {
        const currentPart = num % 1000;
        if (currentPart !== 0) {
          word =
            helper(currentPart) +
            (aboveHundred[i] === "" ? "" : " " + aboveHundred[i]) +
            (word === "" ? "" : " " + word);
        }
        num = Math.floor(num / 1000);
        i++;
      }

      return word.trim();
    },
    getCurrentDate() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
      const day = String(currentDate.getDate()).padStart(2, "0");

      const formattedDate = `${day}/${month}/${year}`;
      return formattedDate;
    },

    getLoanBankAddress() {
      try {
        return this.stock.sale.loan.bank.address;
      } catch (error) {
        return "N/A";
      }
    },
    getLoanBankName() {
      try {
        return this.stock.sale.loan.bank.name;
      } catch (error) {
        return "N/A";
      }
    },

    formatAddress(address) {
      return address.replace(/\n/g, "<br />");
    },

    removeTrailingSlash(str) {
      if (str.endsWith("/")) {
        return str.slice(0, -1);
      }
      return str;
    },
    getImageUrl() {
      var url = this.removeTrailingSlash(apiUrl) + this.company.logoUrl;
      console.log(url);
      return url;
    },
    getStampUrl() {
      var url = apiUrl + this.company.stampUrl;
      return url;
    },
    saveAsPDF() {
      const element = this.$refs.pdfContent;
      const options = {
        margin: [0.5, 0.5, 0.5, 0.5], // Top, right, bottom, left
        filename: "Invoice.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      };
      html2pdf().from(element).set(options).save();
    },
    printContent() {
      const printContents = this.$refs.pdfContent.innerHTML;
      const originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;

      window.print();

      document.body.innerHTML = originalContents;
      window.location.reload();
    },
  },
};
</script>

<style scoped>
.thick-hr {
  border: 1px solid #000;
  margin: 10px 0;
}
.logo-image {
  max-width: 100%; /* Adjust the width to fit the print layout */
}
.stamp-image {
  max-width: 100px;
  max-height: 100px;
}
.footer p {
  margin: 0;
}
@media print {
  .no-print {
    display: none !important;
  }
}
</style>
